import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let currentUser = this.authService.currentUserValue;
		if (currentUser && currentUser.sessionToken) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${currentUser.sessionToken}`,
				},
			});
		}
		return next.handle(request);
	}
}
