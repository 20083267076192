import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authService: AuthService) {}

	canActivate(): Promise<boolean> {
		return new Promise((resolve) => {
			let currentUser = this.authService.currentUserValue;
			if (currentUser && currentUser.sessionToken) {
				resolve(true);
			} else {
				this.router.navigate(['/login']);
				resolve(false);
			}
		});
	}
}
