import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';
import {LoginDto, UserDto} from '../types/dtos/models';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private apiHost = `${environment.apiHost_v2}/auth`;
	private currentUserSubject: BehaviorSubject<LoginDto>;
	public currentUser: Observable<LoginDto>;

	constructor(private http: HttpClient, private router: Router) {
		this.currentUserSubject = new BehaviorSubject<LoginDto>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): LoginDto {
		return this.currentUserSubject.value;
	}

	public login = async (email: string, password: string): Promise<ApiResponse<LoginDto>> => {
		return this.http
			.post<ApiResponse<LoginDto>>(`${this.apiHost}/login`, {email, password})
			.toPromise()
			.then((res) => {
				this.setSession(res.data);
				return res;
			});
	};

	public logout = () => {
		// remove user from local storage and set current user to null
		localStorage.removeItem('currentUser');
		this.currentUserSubject.next(null);
		this.router.navigate(['/login']);
	};

	public requestResetPassword = async (email: string): Promise<ApiResponse<undefined>> =>
		this.http
			.post<ApiResponse<undefined>>(`${this.apiHost}/reset_password`, {email})
			.toPromise();

	public validateToken = async (token: string): Promise<ApiResponse<UserDto>> =>
		this.http
			.post<ApiResponse<UserDto>>(`${this.apiHost}/validate_reset_token`, {token})
			.toPromise();

	public resetNewPassword = async (token: string, password: string): Promise<ApiResponse<LoginDto>> =>
		this.http
			.post<ApiResponse<LoginDto>>(`${this.apiHost}/new_password`, {token, password})
			.toPromise()
			.then((res) => {
				const {} = res.data;
				this.setSession(res.data);
				return res;
			});

	private setSession = (authResult: LoginDto) => {
		localStorage.setItem('currentUser', JSON.stringify(authResult));
		this.currentUserSubject.next(authResult);
	};
}
