import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private router: Router, public authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				return event;
			}),
			catchError((error) => {
				if (error.status === 401 && this.router.url !== '/login') {
					this.authService.logout();
					this.router.navigate(['/login']);
					return new Observable<HttpEvent<any>>();
				}
				return throwError(error);
			})
		);
	}
}
